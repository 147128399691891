@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.footer-main {
  .main-section {
    height: 56px;
    background: $whiteColor;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06);
    .nav {
      margin: 0;
      padding: 0;
      //@include display(flex);
      li {
        display: inline-block;
        margin-right: 30px;
        &.active {
          a {
            color: #ff7f9d;
          }
        }
        a {
          padding: 18px 0;
          display: block;
          color: $redColor;
          font-size: 12px;
          font-weight: 600 !important;
          line-height: 17px;

          &:hover {
            color: #ff7f9d;
          }
        }
      }
    }
    .copyright {
      padding: 18px 0;
      line-height: 17px;
      font-size: 12px;
      font-weight: 400;
      float: right;
      color: #A3A3A3;
    }
  }
}
