@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.cover {
  background-size: cover;
  height: 240px;
  margin-bottom: 40px;
  @include animation(fadeIn 3s ease-in-out);

  .gradient-layer {
    height: 100%;
    @include background-image(linear-gradient(-180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.74) 100%));
    .column {
      height: 100%;
    }
    .content {
      @include display(flex);
      @include align-items(flex-end);
      height: 100%;
      p {
        margin: 0 0;
        font-size: 18px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 24px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.50);
      }
    }
  }
  &.home {
    @include background-image(url('/images/covers/cover-home.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-home@2x.jpg'));
    }
  }
  &.about {
    @include background-image(url('/images/covers/cover-about.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-about@2x.jpg'));
    }
  }
  &.expertise {
    @include background-image(url('/images/covers/cover-expertise.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-expertise@2x.jpg'));
    }
  }
  &.clients {
    @include background-image(url('/images/covers/cover-clients.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-clients@2x.jpg'));
    }
  }
  &.projects {
    @include background-image(url('/images/covers/cover-projects.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-projects@2x.jpg'));
    }
  }
  &.team {
    @include background-image(url('/images/covers/cover-team.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-team@2x.jpg'));
    }
  }
  &.contact {
    @include background-image(url('/images/covers/cover-contact.jpg'));

    @include hidpi(2) {
      @include background-image(url('/images/covers/cover-contact@2x.jpg'));
    }
  }
}

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}