@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body, html {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #575757;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

div.container {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px; }
  div.container::after {
    clear: both;
    content: "";
    display: table; }

ul {
  padding: 0;
  list-style: none; }

ol {
  padding-left: 24px; }

a {
  text-decoration: none;
  cursor: pointer;
  outline: none; }

.column {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .column::after {
    clear: both;
    content: "";
    display: table; }

.row {
  display: block; }
  .row::after {
    clear: both;
    content: "";
    display: table; }

.col-3 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 23.2317613015%; }
  .col-3:last-child {
    margin-right: 0; }

.col-4 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 31.7615656014%; }
  .col-4:last-child {
    margin-right: 0; }

.col-6 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%; }
  .col-6:last-child {
    margin-right: 0; }

.col-8 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 65.8807828007%; }
  .col-8:last-child {
    margin-right: 0; }

.col-9 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 74.4105871005%; }
  .col-9:last-child {
    margin-right: 0; }

.col-12 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%; }
  .col-12:last-child {
    margin-right: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.header-main .column {
  max-width: 68em;
  margin-left: auto;
  margin-right: auto; }
  .header-main .column::after {
    clear: both;
    content: "";
    display: table; }

.header-main .top-section {
  height: 64px;
  padding: 14px 0; }
  .header-main .top-section .logo {
    width: 90px;
    height: 36px;
    background-image: url("/images/logo.png");
    background-image: url("/images/logo.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .header-main .top-section .logo {
        background-image: url("/images/logo@2x.png");
        background-image: url("/images/logo@2x.png");
        background-size: 90px 36px; } }

.header-main .bot-section {
  height: 56px;
  background: #F50039; }
  .header-main .bot-section .nav {
    width: 100%;
    margin: 0;
    padding: 0; }
    .header-main .bot-section .nav li {
      display: inline-block;
      margin-right: 30px; }
      .header-main .bot-section .nav li.active a {
        color: #000; }
      .header-main .bot-section .nav li a {
        padding: 18px 0;
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 600 !important;
        line-height: 17px; }
        .header-main .bot-section .nav li a:hover {
          color: rgba(0, 0, 0, 0.6); }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.footer-main .main-section {
  height: 56px;
  background: #fff;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.06); }
  .footer-main .main-section .nav {
    margin: 0;
    padding: 0; }
    .footer-main .main-section .nav li {
      display: inline-block;
      margin-right: 30px; }
      .footer-main .main-section .nav li.active a {
        color: #ff7f9d; }
      .footer-main .main-section .nav li a {
        padding: 18px 0;
        display: block;
        color: #F50039;
        font-size: 12px;
        font-weight: 600 !important;
        line-height: 17px; }
        .footer-main .main-section .nav li a:hover {
          color: #ff7f9d; }
  .footer-main .main-section .copyright {
    padding: 18px 0;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    float: right;
    color: #A3A3A3; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.cover {
  background-size: cover;
  height: 240px;
  margin-bottom: 40px;
  -webkit-animation: fadeIn 3s ease-in-out;
  -moz-animation: fadeIn 3s ease-in-out;
  animation: fadeIn 3s ease-in-out; }
  .cover .gradient-layer {
    height: 100%;
    background-image: -webkit-linear-gradient(-180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.74) 100%);
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.74) 100%); }
    .cover .gradient-layer .column {
      height: 100%; }
    .cover .gradient-layer .content {
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -moz-box-align: end;
      box-align: end;
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      -o-align-items: flex-end;
      align-items: flex-end;
      -ms-flex-align: end;
      height: 100%; }
      .cover .gradient-layer .content p {
        margin: 0 0;
        font-size: 18px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 24px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); }
  .cover.home {
    background-image: url("/images/covers/cover-home.jpg");
    background-image: url("/images/covers/cover-home.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.home {
        background-image: url("/images/covers/cover-home@2x.jpg");
        background-image: url("/images/covers/cover-home@2x.jpg"); } }
  .cover.about {
    background-image: url("/images/covers/cover-about.jpg");
    background-image: url("/images/covers/cover-about.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.about {
        background-image: url("/images/covers/cover-about@2x.jpg");
        background-image: url("/images/covers/cover-about@2x.jpg"); } }
  .cover.expertise {
    background-image: url("/images/covers/cover-expertise.jpg");
    background-image: url("/images/covers/cover-expertise.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.expertise {
        background-image: url("/images/covers/cover-expertise@2x.jpg");
        background-image: url("/images/covers/cover-expertise@2x.jpg"); } }
  .cover.clients {
    background-image: url("/images/covers/cover-clients.jpg");
    background-image: url("/images/covers/cover-clients.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.clients {
        background-image: url("/images/covers/cover-clients@2x.jpg");
        background-image: url("/images/covers/cover-clients@2x.jpg"); } }
  .cover.projects {
    background-image: url("/images/covers/cover-projects.jpg");
    background-image: url("/images/covers/cover-projects.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.projects {
        background-image: url("/images/covers/cover-projects@2x.jpg");
        background-image: url("/images/covers/cover-projects@2x.jpg"); } }
  .cover.team {
    background-image: url("/images/covers/cover-team.jpg");
    background-image: url("/images/covers/cover-team.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.team {
        background-image: url("/images/covers/cover-team@2x.jpg");
        background-image: url("/images/covers/cover-team@2x.jpg"); } }
  .cover.contact {
    background-image: url("/images/covers/cover-contact.jpg");
    background-image: url("/images/covers/cover-contact.jpg"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .cover.contact {
        background-image: url("/images/covers/cover-contact@2x.jpg");
        background-image: url("/images/covers/cover-contact@2x.jpg"); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.home-column {
  margin-bottom: 60px; }

.logos-column {
  text-align: center; }
  .logos-column .logo-gcsm {
    display: inline-block;
    width: 102px;
    height: 102px;
    background-image: url("/images/logo-gcsm.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      .logos-column .logo-gcsm {
        background-image: url("/images/logo-gcsm@2x.png");
        background-size: cover; } }

h4.team-category {
  /* Consultanți: */
  font-size: 14px;
  font-weight: 400;
  color: #A3A3A3;
  line-height: 19px; }

.solid-column .row {
  margin-bottom: 80px; }
  .solid-column .row:last-child {
    margin-bottom: 0; }

.solid-column .header {
  margin-bottom: 20px; }
  .solid-column .header h2 {
    color: #F50039;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0; }

.solid-column .content h2 {
  font-size: 16px;
  font-weight: 600; }

.solid-column .content b {
  font-weight: 600; }

.solid-column .content a {
  color: #575757;
  text-decoration: underline; }
  .solid-column .content a:hover {
    color: #F50039; }

.solid-column p {
  margin: 20px 0;
  color: #575757;
  font-size: 14px;
  font-weight: 400;
  text-align: justify; }
  .solid-column p:first-child {
    margin-top: 0; }

.solid-column ul {
  padding: 0; }
  .solid-column ul li {
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px; }
  .solid-column ul li.checkmark {
    padding-left: 22px;
    background: no-repeat 0 5px url("/images/icons/checkmark-icon.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .solid-column ul li.checkmark {
        background: no-repeat 0 5px url("/images/icons/checkmark-icon@2x.png");
        background-size: 12px 12px; } }

.light-column .profile-image {
  width: 300px;
  height: 160px;
  margin-bottom: 20px;
  background: #FFF1F1;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.light-column .header {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }
  .light-column .header .icon {
    margin-right: 10px; }
  .light-column .header h3 {
    margin: 6px 0;
    height: 20px;
    font-size: 14px;
    color: #F50039;
    line-height: 20px; }
    .light-column .header h3.uppercase {
      text-transform: uppercase; }

.light-column .content {
  text-align: justify !important; }
  .light-column .content p {
    font-size: 12px;
    color: #575757;
    line-height: 18px; }

.promo-column {
  margin-bottom: 48px; }
  .promo-column a {
    display: block;
    height: 120px;
    box-sizing: border-box;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
    .promo-column a .image {
      width: 100%;
      height: 100%;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px; }
    .promo-column a.wise {
      border: 1px solid #FFB366; }
      .promo-column a.wise .image {
        background-image: url("/images/wise.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%; }
    .promo-column a.cresc {
      border: 1px solid #65C7FF; }
      .promo-column a.cresc .image {
        background-image: url("/images/cresc.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%; }

.row-members {
  margin-bottom: 48px; }
  .row-members:last-child {
    margin-bottom: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.nav-sidebar {
  border: 1px solid #F0F0F0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }
  .nav-sidebar li {
    border-bottom: 1px solid #F0F0F0; }
    .nav-sidebar li:last-child {
      border-bottom: none; }
    .nav-sidebar li.active a {
      color: #F50039; }
    .nav-sidebar li a {
      display: block;
      padding: 10px;
      color: #575757;
      font-size: 12px; }
      .nav-sidebar li a:hover {
        color: #F50039; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.icon {
  width: 32px;
  height: 32px; }
  .icon.icon-expertise {
    background-image: url("/images/icons/expertise-icon.png");
    background-image: url("/images/icons/expertise-icon.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .icon.icon-expertise {
        background-image: url("/images/icons/expertise-icon@2x.png");
        background-image: url("/images/icons/expertise-icon@2x.png");
        background-size: 32px 32px; } }
  .icon.icon-projects {
    background-image: url("/images/icons/projects-icon.png");
    background-image: url("/images/icons/projects-icon.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .icon.icon-projects {
        background-image: url("/images/icons/projects-icon@2x.png");
        background-image: url("/images/icons/projects-icon@2x.png");
        background-size: 32px 32px; } }
  .icon.icon-team {
    background-image: url("/images/icons/team-icon.png");
    background-image: url("/images/icons/team-icon.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .icon.icon-team {
        background-image: url("/images/icons/team-icon@2x.png");
        background-image: url("/images/icons/team-icon@2x.png");
        background-size: 32px 32px; } }
  .icon.icon-checkmark {
    width: 12px;
    height: 12px;
    background-image: url("/images/icons/checkmark-icon.png");
    background-image: url("/images/icons/checkmark-icon.png"); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .icon.icon-checkmark {
        background-image: url("/images/icons/checkmark-icon@2x.png");
        background-image: url("/images/icons/checkmark-icon@2x.png");
        background-size: 12px 12px; } }
