@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.home-column {
  margin-bottom: 60px;
}

.logos-column {
  text-align: center;
  .logo-gcsm {
    display: inline-block;
    width: 102px;
    height: 102px;
    @include retina-image('/images/logo-gcsm', cover, 'png', null, '@2x');
  }
}

h4 {
  &.team-category {
    /* Consultanți: */
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
    line-height: 19px;
  }
}

.solid-column {
  .row {
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .header {
    h2 {
      color: $redColor;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
    }
    margin-bottom: 20px;
  }
  .content {
    h2 {
      font-size: 16px;
      font-weight: 600;
    }
    b {
      font-weight: 600;
    }
    a {
      color: $greyColor;
      text-decoration: underline;

      &:hover {
        color: $redColor;
      }
    }
  }
  p {
    margin: 20px 0;
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    padding: 0;
    li {
      color: #575757;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 4px;
    }
    li.checkmark {
      padding-left: 22px;
      background: no-repeat 0 5px url('/images/icons/checkmark-icon.png');

      @include hidpi(2) {
        background: no-repeat 0 5px url('/images/icons/checkmark-icon@2x.png');
        background-size: 12px 12px;
      }
    }
  }
}

.light-column {
  .profile-image {
    width: 300px;
    height: 160px;
    margin-bottom: 20px;
    background: #FFF1F1;
    @include border-top-radius(2px);
    @include border-bottom-radius(2px);
  }
  .header {
    //margin-bottom: 20px;
    @include display(flex);
    .icon {
      margin-right: 10px;
    }
    h3 {
      margin: 6px 0;
      height: 20px;
      font-size: 14px;
      color: $redColor;
      line-height: 20px;
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }
  .content {
    text-align: justify !important;
    p {
      font-size: 12px;
      //      font-weight: 300;
      color: #575757;
      line-height: 18px;
    }
  }
}

.promo-column {
  margin-bottom: 48px;
  a {
    display: block;
    height: 120px;
    box-sizing: border-box;
    @include border-top-radius(2px);
    @include border-bottom-radius(2px);
    .image {
      width: 100%;
      height: 100%;
      @include border-top-radius(2px);
      @include border-bottom-radius(2px);
    }
    &.wise {
      border: 1px solid #FFB366;
      .image {
        background-image: url("/images/wise.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
    &.cresc {
      border: 1px solid #65C7FF;
      .image {
        background-image: url("/images/cresc.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
  }
}

.row-members {
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
}