@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.nav-sidebar {
  border: 1px solid $lightGreyColor;
  @include border-top-radius(2px);
  @include border-bottom-radius(2px);
  li {
    border-bottom: 1px solid $lightGreyColor;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      a {
        color: $redColor;
      }
    }
    a {
      display: block;
      padding: 10px;
      color: $greyColor;
      font-size: 12px;
      &:hover {
        color: $redColor;
      }
    }
  }
}