@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.header-main {
  .column {
    @include outer-container;
  }
  .top-section {
    height: 64px;
    padding: 14px 0;
    .logo {
      width: 90px;
      height: 36px;
      @include background-image(url('/images/logo.png'));

      @include hidpi(2) {
        @include background-image(url('/images/logo@2x.png'));
        background-size: 90px 36px;
      }
    }
  }
  .bot-section {
    height: 56px;
    background: $redColor;
    .nav {
      width: 100%;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 30px;
        &.active {
          a {
            color: $black;
          }
        }
        a {
          padding: 18px 0;
          display: block;
          color: $whiteColor;
          font-size: 14px;
          font-weight: 600 !important;
          line-height: 17px;

          &:hover {
            color: hsla(0, 0, 0, .6);
          }
        }
      }
    }
  }
}
