@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

.icon {
  width: 32px;
  height: 32px;
  &.icon-expertise {
    @include background-image(url('/images/icons/expertise-icon.png'));

    @include hidpi(2) {
      @include background-image(url('/images/icons/expertise-icon@2x.png'));
      background-size: 32px 32px;
    }
  }
  &.icon-projects {
    @include background-image(url('/images/icons/projects-icon.png'));

    @include hidpi(2) {
      @include background-image(url('/images/icons/projects-icon@2x.png'));
      background-size: 32px 32px;
    }
  }
  &.icon-team {
    @include background-image(url('/images/icons/team-icon.png'));

    @include hidpi(2) {
      @include background-image(url('/images/icons/team-icon@2x.png'));
      background-size: 32px 32px;
    }
  }

  &.icon-checkmark {
    width: 12px;
    height: 12px;
    @include background-image(url('/images/icons/checkmark-icon.png'));

    @include hidpi(2) {
      @include background-image(url('/images/icons/checkmark-icon@2x.png'));
      background-size: 12px 12px;
    }
  }
}